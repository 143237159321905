<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authComputed } from "@/state/helpers";
import store from "@/state/store";
const _ = require('lodash')
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 2 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Performance Assessment",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    this.performanceId = this.$route.params.performanceId;
    this.getPerformanceAssessmentDetails();
  },
  data() {
    return {
      title: "Performance Assessment",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Performance Assessment",
          active: true,
        },
      ],
      texts: [],
      assessStatus: 0,
      goals: [],
      start: "",
      end: "",
      duration: 0,
      submitted: false,
      count: 0,
      assessments: [],
      employee: null,
      supervisor: null,
      start_date: null,
      end_date: null,
    };
  },
  methods: {
   /* addField() {
      this.texts.push({
        id: this.count++,
        goal: "",
        goalEdit: false,
        challenge: "",
        challengeEdit: false,
        accomplishment: "",
        accomplishmentEdit: false,
        support: "",
        supportEdit: false,
        next_step: "",
        nextStepEdit: null,
        update: "",
      });
      this.count++;
      console.log(this.texts);
    },
    delField(index) {
      if (index > 0) {
        this.texts.splice(index, 1);
      }
    },*/
    async getPerformanceAssessmentDetails(){
      const url = `${this.ROUTES.performanceImprovement}/get-supervisor-performance-assessment/${this.performanceId}`;
      await this.apiGet(url).then((res) => {
        const { data } = res;
        this.performance_dev = data.performance;
        this.performanceId = this.performance_dev.ppm_id;
        this.assessStatus = this.performance_dev.ppm_status;
        switch (parseInt(this.performance_dev.ppm_status)) {
          case 0:
            this.status = 'Pending';
            break;
          case 1:
            this.status = 'Approved';
            break;
          case 2:
            this.status = 'Declined';
            break;
        }
        this.employee = `${this.performance_dev.employee?.emp_first_name } ${ this.performance_dev.employee?.emp_last_name } (${ this.performance_dev.employee?.emp_unique_id })`;
        this.supervisor = `${this.performance_dev.supervisor?.emp_first_name } ${ this.performance_dev.supervisor?.emp_last_name } (${ this.performance_dev.supervisor?.emp_unique_id })`;
        this.start_date = new Date(this.performance_dev.ppm_start_date).toDateString();
        this.end_date = new Date(this.performance_dev.ppm_end_date).toDateString();
        if(!_.isNull(this.performance_dev.ppm_accomplishments) || !_.isEmpty(this.performance_dev.ppm_accomplishments)){
        //if(!_.isNull(this.performance_dev.ppm_achievement) || !_.isEmpty(this.performance_dev.ppm_achievement)){
            this.assessStatus = 1;
        }

        const assessments = data.performance.assessment;
        assessments.map(assess=>{
          this.texts.push({
            id: assess.ppa_id,
            goal: assess.ppa_goal,
            measure: assess.ppa_performance_measure
          });
        });

      });
    },
    savePerformanceAssessmentChanges() {
      //const employeeID = this.getEmployee.emp_id;
      const url = `${this.ROUTES.performanceImprovement}/save-performance-assessment-changes`;
      this.goals = [];
      let validForm = true;
      this.texts.every(async (field) => {
        if (field.goal === "" || !field.goal) {
          this.apiFormHandler("Invalid Goal Fields");
          validForm = false;
          return false;
        }
        const data = {
          goal: field.goal,
          measure: field.measure,
        };
        this.goals.push(data);
        return true;
      });
      if (validForm) {
        const data = {
          goals:this.goals,
          performanceId:this.performanceId
        }
        this.apiPost(url, data, "Add goals Error").then(() => {
          this.apiResponseHandler("Process Complete", "Goals Added");
          this.getSelfAssessment();


        });
      }
    },
    approvePerformanceAssessment() {
      const url = `${this.ROUTES.performanceImprovement}/approve-performance-assessment`;
        const data = {
          performanceId:this.performanceId,
          supervisorId:this.getEmployee.emp_id
        }
        this.apiPost(url, data, "Approving assessement Error").then(() => {
          this.apiResponseHandler("Process Complete", "Goals Added");
          this.getSelfAssessment();


        });

    },

    stripTags(content) {
      return content.replace(/<.*?>/g, "");
    },
    test(event) {
      console.log(event.target.value);
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>

<style>
textarea {
  resize: none;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button
        class="btn btn-success"
        @click="$router.push({ name: 'performance-assessment' })"
      >
        <i class="mdi mdi-view-list mr-2"></i>
        Performance Assessment
      </b-button>
    </div>
    <scale-loader v-if="apiBusy" />


    <div v-else class="row">
      <div
        class="col-lg-8"
      >
        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4">
              <h5 class="font-size-14 mb-0">Performance Measurement</h5>
            </div>
            <form @submit.prevent="savePerformanceAssessmentChanges">
              <b-table-simple hover responsive bordered outlined>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>S/n</b-th>
                    <b-th>Goal</b-th>
                    <b-th>Performance Measure</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(field, index) in texts" :key="index">
                    <b-td style="width: 1%">
                      {{ index + 1 }}
                    </b-td>
                    <b-td style="width: 19%">
                      <textarea
                        v-if="assessStatus === 0"
                        type="text"
                        v-model="field.goal "
                        rows="6"
                        class="form-control"
                      />
                      <p v-else v-text="field.goal || '' "></p>
                    </b-td>
                    <b-td style="width: 11%">
                      <div class="form-group">
                        <textarea
                          type="text"
                          v-if="assessStatus === 0 "
                          v-model="field.measure "
                          rows="6"
                          class="form-control"
                        />
                        <p v-else v-text="field.measure || '' "></p>
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div class="row" v-if="assessStatus === 0">
                <div class="col-12">
                  <b-button
                    v-if="!submitting"
                    class="btn btn-success btn-block mt-4"
                    type="submit"
                    :disabled="assessStatus === 1 ? true : false"
                  >
                    Save Changes
                  </b-button>
                  <b-button
                    v-else
                    disabled
                    class="btn btn-success btn-block mt-4"
                    type="submit"
                  >
                    Submitting...
                  </b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4 d-flex justify-content-between">
              <div class="d-inline mb-0">
                <h5 class="font-size-14 mb-0">Other Details</h5>
              </div>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <span>Employee Name</span>
              <span>
                {{ employee }}
              </span>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <span>Supervisor</span>
              <span> {{ supervisor }} </span>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <span>Start Date</span>
              <span>
                {{ start_date }}
              </span>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <span>End Date</span>
              <span>
                {{ end_date }}
              </span>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <span>Status</span>
              <span> {{ status }} </span>
            </div>
          </div>
        </div>

        <div class="card" v-if="assessStatus === 0">
          <div class="card-body">
            <div class="p-3 bg-light mb-4 d-flex justify-content-between">
              <div class="d-inline mb-0">
                <h5 class="font-size-14 mb-0">Authorization</h5>
              </div>
            </div>
            <form @submit.prevent="approvePerformanceAssessment">
              <div class="d-flex justify-content-center">
                <b-button
                  v-if="!submitting"
                  class="btn btn-success btn-block mt-4"
                  type="submit"
                  :disabled="assessStatus === 1 ? true : false"
                >
                  Approve Performance Assessment
                </b-button>
                <b-button
                  v-else
                  disabled
                  class="btn btn-success btn-block mt-4"
                  type="submit"
                >
                  Approving...
                </b-button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>
